<template>
  <div class="container">
    <div class="cardBox">
      <div class="mainauto">
        <el-row>
          <el-col :span="11"
            ><div class="grid-content bg-purple industry_introduce">
              <div class="introduce_title">
                支持不动产初期信息导入、资产全周期管理
              </div>

              <ul class="introduce_main">
                <li>
                  满足企业及政府单位对各资产的分类录入要求.提供新建、修改、删除等权限操作。授权可根据人、部门、岗位、角色等多维度设置。
                </li>
                <li>
                  管理人员将购买的资产信息录入系统后，系统自动生成资产的编号、进行全资产管理。
                </li>
              </ul>
            </div></el-col
          >
          <el-col :span="13"
            ><div class="grid-content bg-purple imgbox">
              <img src="@/assets/product/014.png" alt="" /></div
          ></el-col>
        </el-row>
      </div>
    </div>
    <div class="cardBox">
      <div class="mainauto">
        <el-row>
          <el-col :span="13"
            ><div class="grid-content bg-purple imgbox">
              <img class="small-img" src="@/assets/product/005.png" alt="" /></div
          ></el-col>
          <el-col :span="11"
            ><div class="grid-content bg-purple industry_introduce">
              <div class="introduce_title">
                灵活的合同管理,企业资产合同情况一目了然
              </div>
              <ul class="introduce_main">
                <li>
                  用户发起审批，实现合同档案的建立，线上拟制出合同,线上打印，快捷方便。
                </li>
                <li>灵活的合同管理,企业资产合同情况一目了然。</li>
              </ul>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <div class="cardBox">
      <div class="mainauto">
        <el-row>
          <el-col :span="11"
            ><div class="grid-content bg-purple industry_introduce">
              <div class="introduce_title">
                移动扫码，上报维修多种方式全面提升资产使用效率
              </div>

              <ul class="introduce_main">
                <li>移动扫码，上报维修多种方式全面提升资产使用效率。</li>
                <li>
                  日常巡检、安全巡检、维修申请选择对应房产时，流程结束自动更新房产信息，增加巡检及维修记录。
                </li>
              </ul>
            </div></el-col
          >
          <el-col :span="13"
            ><div class="grid-content bg-purple imgbox">
              <img src="@/assets/product/003.png" alt="" /></div
          ></el-col>
        </el-row>
      </div>
    </div>
    <div class="cardBox">
      <div class="mainauto">
        <el-row>
          <el-col :span="13"
            ><div class="grid-content bg-purple imgbox">
              <img class="small-img" src="@/assets/product/006.png" alt="" /></div
          ></el-col>
          <el-col :span="11"
            ><div class="grid-content bg-purple industry_introduce">
              <div class="introduce_title">
                统一管理承租单位、施工单位，决策依据，工作更轻松
              </div>
              <ul class="introduce_main">
                <li>用户可在租房申请处提交申请，投诉举报，发表意见建议。</li>
                <li>方便管理者第一时间了解客户真实需求,快速解决问题。</li>
              </ul>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "real-estate",
};
</script>

<style scoped>
.container {
  margin-top: 56px;
  width: 100%;
}
.cardBox {
  width: 100%;

  margin-bottom: 40px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.mainauto {
  width: 1200px;
  margin: 0 auto;
}
.grid-content {
  height: 426px;
}
.imgbox {
  text-align: right;
  padding-right: 90px;
}
.imgbox img {
  width: 170px;
  height:284px;
  margin-top: 60px;
}
.imgbox .small-img{
  width:240px;
}
.industry_introduce {
  padding: 40px 0 0 90px;
  background: #fff;
  color: #333;
}
.cardBox:nth-child(2n) .mainauto .el-row .industry_introduce {
  padding: 40px 0 0 56px;
}
.introduce_main {
  font-size: 20px;
}
.introduce_main li {
  position: relative;
  padding-left: 20px;
  line-height: 26px;
  margin-bottom: 20px;
}
.introduce_main li::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: #333;
  border-radius: 50%;
  left: 0;
  top: 10px;
}

.cardBox:nth-child(2n) .mainauto .el-row .imgbox {
  text-align: left;
  padding: 0 0 0 90px;
}
.introduce_title {
  font-size: 30px;
  margin-bottom: 32px;
}
.container .cardBox:last-child {
  height: 630px;
  margin-bottom: 0;
  background: url("../../assets/index/bottom_bg.png") no-repeat bottom;
  background-size: 100% 50%;
}
</style>
